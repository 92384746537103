import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance, initializeMsal } from './authConfig';
import axios from 'axios';
import { isTokenExpired, acquireTokenSilently } from './tokenManager'; // Import the token manager
import Header from './components/Header';
import PageAdmin from './components/PageAdmin';
import PageSearch from './components/PageSearch';
import PageLogin from './components/PageLogin';
import PageAnalyser from './components/PageAnalyser';
import ErrorBoundary from './components/ErrorBoundary';
import PageNotFound from './components/PageNotFound'; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './components/ProtectedRoute';
import { RoleProvider } from './RoleContext';
import PageUnauthorized from './components/PageUnauthorized'; // Import the UnauthorizedPage component
import { CSSTransition, TransitionGroup } from 'react-transition-group'; // Import transition components
import { RequestProvider } from './contexts/RequestContext';

const App = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login' || location.pathname === '/';
  const registeredRoutes = [
    '/',
    '/login',
    '/search',
    '/admin',
    '/analyser',
    '/unauthorized'
  ];
  
  const isRegisteredRoute = registeredRoutes.includes(location.pathname);
  const [isNavExpanded, setIsNavExpanded] = useState(true);
  const nodeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsNavExpanded(false); // Collapse on mobile
      } else {
        setIsNavExpanded(true); // Expand on desktop
      }
    };

    // Initial check
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Run once on mount

  useEffect(() => {
    const handleRedirect = async () => {
      await msalInstance.handleRedirectPromise();
    };

    const initializeApp = async () => {
      await initializeMsal();
      await handleRedirect();
    };

    initializeApp();

    axios.interceptors.request.use(async (config) => {
      let token = sessionStorage.getItem('token');

      if (isTokenExpired(token)) {
        token = await acquireTokenSilently();
      }

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    }, (error) => {
      return Promise.reject(error);
    });
  }, []);

  // Update the body class based on the current route
  useEffect(() => {
    if (isRegisteredRoute) {
      document.body.classList.remove('not-found-page');
    } else {
      document.body.classList.add('not-found-page');
    }
  }, [location.pathname, isRegisteredRoute]); // Run this effect whenever the pathname changes

  return (
    <MsalProvider instance={msalInstance}>
      <RoleProvider>
        <RequestProvider>
          <div className={`app-container ${isNavExpanded ? '' : 'nav-collapsed'} ${isLoginPage ? 'login-page d-flex justify-content-center' : ''}`}>
            {!isLoginPage && isRegisteredRoute && <Header isExpanded={isNavExpanded} setIsExpandedState={setIsNavExpanded} />}
            <div className={`main-content ${isNavExpanded ? '' : 'nav-collapsed'} ${isLoginPage ? 'd-flex justify-content-center' : ''}`}>
              <ErrorBoundary>
                <TransitionGroup>
                  <CSSTransition
                    key={location.key} // Use location.key to trigger transition on route change
                    classNames="fade" // Define your transition class
                    timeout={300} // Duration of the transition
                    nodeRef={nodeRef}
                  >
                    <div ref={nodeRef}>
                      <Routes>
                        <Route path="/" element={<PageLogin />} />
                        <Route path="/login" element={<PageLogin />} />
                        <Route path="/search" element={<ProtectedRoute element={<PageSearch />} requiredRoles={['cognitiveSearch', 'admin']} />} />
                        <Route path="/admin" element={<ProtectedRoute element={<PageAdmin />} requiredRoles={['admin']} />} />
                        <Route path="/analyser" element={<ProtectedRoute element={<PageAnalyser />} requiredRoles={['admin']} />} />
                        <Route path="/unauthorized" element={<PageUnauthorized />} />
                        <Route path="*" element={<PageNotFound />} /> {/* Catch-all route */}
                      </Routes>
                    </div>
                  </CSSTransition>
                </TransitionGroup>
                <ToastContainer />
              </ErrorBoundary>
            </div>
          </div>
        </RequestProvider>
      </RoleProvider>
    </MsalProvider>
  );
}

export default App;
