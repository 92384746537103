import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { FaSync } from "react-icons/fa"; // Import refresh icon
import { AZURE_SHAREPOINT_SITE_URL, AZURE_SHAREPOINT_OUTPUT_FILES_FOLDER } from '../constants';
import GridLoader from 'react-spinners/GridLoader'; // Import GridLoader

const SharePointDocumentList = () => {
  const [documents, setDocuments] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // Add state for search query
  const [sortOption, setSortOption] = useState('date'); // Change default sort option to 'date'
  const [loading, setLoading] = useState(false); // Add loading state

  const fetchDocuments = async () => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      // Step 1: Get Site ID
      const siteResponse = await axios.get(`https://graph.microsoft.com/v1.0/sites/${AZURE_SHAREPOINT_SITE_URL}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const siteId = siteResponse.data.id;
      // Step 2: Get Drive ID
      const driveResponse = await axios.get(`https://graph.microsoft.com/v1.0/sites/${siteId}/drives`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const driveId = driveResponse.data.value[0].id;
        
      // Step 3: Get the ID of the "output-files" folder
      const folderResponse = await axios.get(
        `https://graph.microsoft.com/v1.0/drives/${driveId}/root/children`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      const outputFilesFolder = folderResponse.data.value.find(folder => folder.name === AZURE_SHAREPOINT_OUTPUT_FILES_FOLDER);
      const outputFolderId = outputFilesFolder.id;

      // Step 4: Fetch Documents from the "output-files" folder
      const documentsResponse = await axios.get(
        `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${outputFolderId}/children`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      setDocuments(documentsResponse.data.value);
    } catch (error) {
      setErrorMessage('Error fetching documents from SharePoint');
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false when fetching ends
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const filteredAndSortedDocuments = useMemo(() => {
    let result = documents.filter(doc =>
      doc.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    result.sort((a, b) => {
      if (sortOption === 'name') {
        return a.name.localeCompare(b.name);
      } else if (sortOption === 'date') {
        return new Date(b.lastModifiedDateTime) - new Date(a.lastModifiedDateTime); // Sort by date descending
      }
      return 0;
    });

    return result;
  }, [documents, searchQuery, sortOption]);

  const resetFilters = () => {
    setSortOption('name');
    setSearchQuery('');
    fetchDocuments(); // Fetch documents after resetting filters
  };

  return (
    <div className="document-list">
      <h2>Generated Documents</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search documents by name..."
        value={searchQuery} // Add state for search query
        onChange={(e) => setSearchQuery(e.target.value)} // Add handler for search input
        className="search-input"
        aria-label="Search documents"
      />

      {/* Sort and Filter Controls */}
      <div className="sort-filter-controls">
        <label htmlFor="sort-select">Sort by:</label>
        <select
          id="sort-select"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          aria-label="Sort options"
        >
          <option value="name">Name</option>
          <option value="date">Modified Date</option>
        </select>

        <button onClick={resetFilters} className="reset-filters" aria-label="Reset filters">Reset Filters</button>
        <FaSync className="sync-icon refresh-button" aria-label="Refresh documents" onClick={fetchDocuments} />
      </div>

      {/* Loading Indicator for Content */}
      <div className="table-container">
        
          <table className="document-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Modified Date</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
            {loading ? (
                <tr>
                  <td colSpan="3" style={{ textAlign: 'center' }}>
                    <GridLoader loading={loading} size={15}
                    color="#7b7b7b" />
                  </td>
                </tr>
            ) : (
              filteredAndSortedDocuments.length > 0 ? (
                filteredAndSortedDocuments.map(doc => (
                  <tr key={doc.id}>
                    <td>
                      <a href={doc.webUrl} target="_blank" rel="noopener noreferrer">
                        {doc.name}
                      </a>
                    </td>
                    <td>{new Date(doc.lastModifiedDateTime).toLocaleString()}</td>
                    <td>{doc.createdBy?.user?.displayName || 'Unknown'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" style={{ textAlign: 'center' }}>No documents found.</td>
                </tr>
              )
            )}
            </tbody>
          </table>

      </div>
    </div>
  );
};

export default SharePointDocumentList;
