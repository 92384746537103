/* eslint-disable */
import React, { useState, useCallback } from 'react';
import { useRequest } from '../contexts/RequestContext'; // Import the custom hook
import AssistantOutputManager from './AssistantOutputManager';
import '../styles/AdminPage.css';
import DocumentList from './DocumentList'; // Import the new component
import axios from 'axios'; // Import axios for API calls
import { API_BASE_URL } from '../constants'; // Import API base URL
import SharePointDocumentList from './SharePointDocumentList'; // Import the new component
import { useMediaQuery } from 'react-responsive';

const PageAdmin = () => {
  const { setRequestInProgress } = useRequest(); // Get the setter from context
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documents, setDocuments] = useState([]); // State for documents
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [successMessage, setSuccessMessage] = useState(''); // State for success messages
  const [isLoading, setIsLoading] = useState(false); // State for loading
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    validateFiles(files);
  };

  const validateFiles = (files) => {
    setSuccessMessage('');
    const allowedTypes = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/pdf' // Allow PDF files
    ]; 
    const maxSize = 25 * 1024 * 1024; // Change limit to 25 MB
    let validFiles = [];
    let errorMessages = [];

    files.forEach(file => {
      if (!allowedTypes.includes(file.type)) {
        errorMessages.push(`Invalid file type: ${file.name}. Please upload a .docx or PDF file.`);
      } else if (file.size > maxSize) {
        errorMessages.push(`File size exceeds 25 MB: ${file.name}. Please upload a smaller file.`);
      } else {
        validFiles.push(file);
      }
    });

    if (errorMessages.length > 0) {
      setErrorMessage(errorMessages.join(' '));
      setSelectedFiles([]); // Clear files if there are errors
    } else {
      setErrorMessage(''); // Clear error message
      setSelectedFiles(validFiles); // Store the valid files
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    setSuccessMessage('');
    setSelectedFiles(selectedFiles.filter(file => file !== fileToRemove)); // Remove the selected file
  };

  const handleDrop = (event) => {
    setSuccessMessage('');
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    validateFiles(droppedFiles); // Validate dropped files
  };

  const handleDragOver = (event) => {
    setSuccessMessage('');
    event.preventDefault(); // Prevent default behavior to allow drop
  };

  const handleSubmit = async () => {
    if (isLoading || selectedFiles.length === 0) return; // Prevent submission if loading or no files
    setIsLoading(true);
    setSuccessMessage(''); // Clear success message before upload
    setRequestInProgress(true); // Set request in progress to true

    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('sop_documents', file); // Append each file to the FormData
    });

    try {
      const response = await axios.post(`${API_BASE_URL}/sop-operations/upload-sop-files`, formData, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status === 'success') {
        setSuccessMessage('Files uploaded successfully!'); // Confirmation message
        await fetchDocuments(); // Call fetchDocuments after successful upload
        setSelectedFiles([]); // Clear selected files after successful upload
        document.getElementById('file-upload').value = ''; // Clear the file input
      } else {
        throw new Error('Upload failed.'); // Handle unexpected response
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during upload.'); // Handle error
    } finally {
      setIsLoading(false); // Reset loading state
      setRequestInProgress(false); // Reset request in progress after processing
    }
  };

  const fetchDocuments = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/sop-operations/list-sop-files`, {
        headers: {
          'accept': 'application/json',
        },
      });
      // Update documents state if needed
      setFetchTrigger(prev => prev + 1); // Increment fetchTrigger
    } catch (error) {
      console.error('Error fetching documents:', error.message);
    }
  }, []);

  return (
    <div className="container-admin">
        <h1 className="greeting text-center">Admin Dashboard</h1>
        {!isMobile && <p className="lead text-center">Manage your uploaded documents and assistant outputs.</p>}
  
      {/* Upload Documents Form */}
      <div className="upload-documents-form" onDrop={handleDrop} onDragOver={handleDragOver}>
        <h2>Upload Documents</h2>
        {!isMobile && <p className="helper-text">Allowed file type: .docx or .pdf (max size: 25 MB)</p>}
        <div className={`drag-drop-area ${isLoading ? 'disabled-drag-drop' : ''}`} onClick={() => document.getElementById('file-upload').click()}>
          <input 
            type="file" 
            id="file-upload" 
            accept=".docx, .pdf" 
            onChange={handleFileChange} 
            style={{ display: 'none' }}
            multiple
          />
          <p>{isMobile ? 'Tap to select a file' : 'Drag and drop your file here, or click to select a file.'}</p>
        </div>
        {selectedFiles.length > 0 && (
          <div className="file-list">
              <h5 className="p-2">Selected Files:</h5>
              <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>
                  {file.name} 
                  <button onClick={() => handleRemoveFile(file)} className="remove-button">Remove</button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <button onClick={handleSubmit} disabled={isLoading || selectedFiles.length === 0} className="proceed-button">
          {isLoading ? 'Uploading...' : 'Upload'}
        </button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
      </div>
  

      <DocumentList fetchDocuments={fetchDocuments} fetchTrigger={fetchTrigger} isMobile={isMobile} />
      <SharePointDocumentList isMobile={isMobile} />
    </div>
  );
};

export default PageAdmin;
