import { msalInstance, loginRequest } from './authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const isTokenExpired = (token) => {
  if (!token) return true;
  const payload = JSON.parse(atob(token.split('.')[1]));
  const expirationTime = payload.exp * 1000; // Convert to milliseconds
  return Date.now() >= expirationTime;
};

export const acquireTokenSilently = async () => {
  const account = msalInstance.getAllAccounts()[0];
  if (account) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: account,
      });
      sessionStorage.setItem('token', response.accessToken);
      return response.accessToken;
    } catch (error) {
      console.error('Silent token acquisition failed', error);
      if (error instanceof InteractionRequiredAuthError) {
        // Handle interaction required error
        return null;
      }
    }
  }
  return null;
};
