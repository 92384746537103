import React, { useRef, useEffect } from 'react';
import { FiUser } from "react-icons/fi";
import { RiRobot3Line } from "react-icons/ri";
import ReactMarkdown from 'react-markdown'; // Import react-markdown
import { PuffLoader } from 'react-spinners';


const ChatWindow = ({ chatHistory }) => {
  const messagesEndRef = useRef(null);
  const isDarkMode = document.body.classList.contains('dark-mode'); // Check for dark mode

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Retrieve chat history from sessionStorage
    const storedChatHistory = sessionStorage.getItem('chatHistory');
    let parsedStoredChatHistory = [];

    // Check if storedChatHistory is a valid JSON string before parsing
    if (storedChatHistory !== null) {
      try {
        parsedStoredChatHistory = JSON.parse(storedChatHistory);
      } catch (error) {
      }
    }
    // console.log("Chat history: ", chatHistory);
    // console.log("Chat history includes loading message: ", chatHistory.includes("loading-message"));
    // Check if any message in chatHistory is a loading message
    const hasLoadingMessage = chatHistory.some(message => 
      typeof message.content === 'object' || 
      (typeof message.content === 'string' && message.content.includes("loading-message"))
    );

    // Update sessionStorage only if the new chatHistory is longer and doesn't have a loading message
    if (chatHistory.length >= parsedStoredChatHistory.length && !hasLoadingMessage) {
      console.log("Updating chat history");
      sessionStorage.setItem('chatHistory', JSON.stringify(chatHistory));
    }

    // Check if chatHistory has more than 150 words
    const wordCount = parsedStoredChatHistory.reduce((count, message) => {
      return count + (typeof message.content === 'string' ? message.content.split(' ').length : 0);
    }, 0);

    // Scroll to bottom only if word count exceeds 150
    if (wordCount > 150) {
      scrollToBottom();
    }
  }, [chatHistory]);

  return (
    <div className="chat-window">
      <div className="message-container">
        {chatHistory.map((message, index) => (
          <div key={index} className={`message ${message.type}`}>
            <div className="message-content">
              {message.type === 'assistant' && <div className="avatar"><RiRobot3Line /></div>}
              {message.type === 'user' && <div className="avatar"><FiUser /></div>}
              {typeof message.content === 'string' && !message.content.includes("loading-message") && (
                <div className="message-text pl-2">
                  <ReactMarkdown>{typeof message.content === 'string' ? message.content : JSON.stringify(message.content)}</ReactMarkdown>
                </div>
              )}
              {typeof message.content === 'object' && (
                <div className="message-text pl-2">
                  <div className="loading-message">
                    <PuffLoader
                      color={isDarkMode ? "#cccccc" : "#000000"}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                </div>
              )}
            </div>
            <span className="timestamp">{new Date(message.timestamp).toLocaleString()}</span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ChatWindow;
