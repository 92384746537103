import React from 'react';
import '../styles/Modal.css'; // Ensure you have the correct CSS file

const AlertBox = ({ message, onClose, theme }) => {
    return (
      <div className={`custom-alert-modal ${theme}`}>
        <div className="custom-alert-content">
          <p>{message}</p>
          <div className="custom-alert-buttons">
            <button className="custom-alert-button" onClick={onClose}>OK</button>
          </div>
        </div>
      </div>
    );
};

export default AlertBox;
