import React, { useEffect} from 'react';
import { useMsal } from "@azure/msal-react";
import { Navigate } from 'react-router-dom';
import { useRole } from '../RoleContext';
import ContentLoader from 'react-content-loader';

const ProtectedRoute = ({ element, requiredRoles }) => {
  const { accounts } = useMsal();
  const { userRole, fetchUserGroups } = useRole();
  const isDarkMode = localStorage.getItem('darkMode') === 'true';

  // Set colors based on the mode
  const backgroundColor = isDarkMode ? "#333" : "#f3f3f3"; // Dark mode background
  const foregroundColor = isDarkMode ? "#ecebeb" : "#000"; // Light mode foreground

  useEffect(() => {
    if (accounts.length > 0 && userRole === null) {
      fetchUserGroups();
    }
  }, [accounts, userRole, fetchUserGroups]);

  // If no accounts are found, redirect to login
  if (accounts.length === 0) {
    return <Navigate to="/login" replace />;
  }

  // If userRole is not set yet, show loading state
  if (userRole === null) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}>
        <ContentLoader 
          speed={2}
          width={400}
          height={160}
          viewBox="0 0 400 160"
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
        >
          <rect x="0" y="20" rx="5" ry="5" width="600" height="20" /> 
          <rect x="0" y="60" rx="5" ry="5" width="600" height="20" /> 
          <rect x="0" y="100" rx="5" ry="5" width="600" height="20" /> 
          <rect x="0" y="140" rx="5" ry="5" width="600" height="20" /> 
        </ContentLoader>
        {/* <p style={{ marginTop: '20px', fontSize: '18px', color: foregroundColor }}>Loading...</p> */}
      </div>
    );
  }

  // If user is unauthorized, redirect to unauthorized page
  if (userRole === 'unauthorized') {
    return <Navigate to="/unauthorized" replace />;
  }

  // Check if the user's role is in the required roles
  if (requiredRoles && !requiredRoles.includes(userRole)) {
    return <Navigate to="/unauthorized" replace />;
  }

  // Render the protected element if all checks pass
  return element;
};

export default ProtectedRoute;