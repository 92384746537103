import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/NotFoundPage.css'; // Import the CSS file
import { useRole } from '../RoleContext';

const PageNotFound = () => {
  const navigate = useNavigate();
  const { fetchUserGroups } = useRole();
  
  const handleRedirect = async () => {
    await fetchUserGroups(); 
    navigate('/'); // Redirect to the home/login page
  };

  return (
    <div className="not-found-container">
      <div className="not-found-text">
        <h1>Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        <button className="not-found-button p-2 mt-4" onClick={handleRedirect}>
          Go to Login / Home Page
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
