// PageSearch.js
import React, { useState, useEffect, useCallback } from 'react';
import { useRequest } from '../contexts/RequestContext'; // Import the custom hook
import ChatWindow from './ChatWindow';
import '../styles/SearchPage.css';
import { IoIosSend } from "react-icons/io";
import { API_BASE_URL } from '../constants'; // Import API base URL
import { PuffLoader } from 'react-spinners'; // Import PropagateLoader
import { LuListRestart } from "react-icons/lu";
import { useMsal } from "@azure/msal-react"; // Import useMsal
import { acquireTokenSilently} from '../tokenManager';


const PageSearch = () => {
  const { setRequestInProgress } = useRequest(); // Get the setter from context
  const [isSearched, setIsSearched] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [isDarkMode, setIsDarkMode] = useState(false); // New dark mode state
  const { accounts } = useMsal(); // Get the logged-in accounts
  // const [streamedResponse, setStreamedResponse] = useState('');
  // Keep threadId as it's an optional parameter
  const [threadId, setThreadId] = useState(null);

  useEffect(() => {
    // Check if there is chat history in sessionStorage
    const storedChatHistory = sessionStorage.getItem('chatHistory');
    if (storedChatHistory && storedChatHistory.length > 0) {
      setIsSearched(true); // Set to true if there is chat history
      setChatHistory(JSON.parse(storedChatHistory)); // Load chat history
    }
  }, []); // Run once on component mount

  useEffect(() => {
    const checkDarkMode = () => {
      setIsDarkMode(document.body.classList.contains('dark-mode'));
    };

    checkDarkMode(); // Check initially
    
    // Set up a MutationObserver to watch for changes to the body's class list
    const observer = new MutationObserver(checkDarkMode);
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    return () => observer.disconnect(); // Clean up the observer on component unmount
  }, []);

  const handleSearch = async (content) => {
    setIsSearched(true);
    const newMessage = { type: 'user', content: content, timestamp: new Date() };
    setChatHistory(prevHistory => [...prevHistory, newMessage]);
    setIsLoading(true);
    setRequestInProgress(true); // Set request in progress to true

    // Add a loading message to chat history
    const loadingMessage = { 
      type: 'assistant', 
      content: (
        <div className="loading-message">
          <PuffLoader
            color={isDarkMode ? "#cccccc" : "#000000"}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ), 
      timestamp: new Date() 
    };
    setChatHistory(prevHistory => [...prevHistory, loadingMessage]);

    // Call the API with the user's query
    await handleCognitiveSearch(content);
    setIsLoading(false);
    setRequestInProgress(false); // Reset request in progress after processing
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (inputValue.trim() && !isLoading) {
      const newMessage = { type: 'user', content: inputValue, timestamp: new Date() };
      setChatHistory(prevHistory => [...prevHistory, newMessage]);
      setInputValue('');
      setIsSearched(true);

      setIsLoading(true);
      setRequestInProgress(true); // Set request in progress to true

      // Add a loading message to chat history
      const loadingMessage = { 
        type: 'assistant', 
        content: (
          <div className="loading-message">
            <PuffLoader
              color={isDarkMode ? "#cccccc" : "#000000"}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ), 
        timestamp: new Date() 
      };
      setChatHistory(prevHistory => [...prevHistory, loadingMessage]);

      // Call AI response logic with thread_id
      await handleCognitiveSearch(inputValue);

      // Clear the input after processing
      setInputValue('');
      setIsLoading(false);
      setRequestInProgress(false); // Reset request in progress after processing
    }
  };

  // Modified function to handle streamed cognitive search
  const handleCognitiveSearch = useCallback(async (query) => {
    try {
      let url = `${API_BASE_URL}/sop-operations/streamed_cognitive_search?query=${encodeURIComponent(query)}`;
      if (threadId) {
        url += `&thread_id=${threadId}`;
      }

      const response = await fetch(url, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${await acquireTokenSilently()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();


      // Use a separate function to handle the stream processing
      const processStream = async () => {
        let accumulatedResponse = '';

        const updateChatHistory = (message) => {
          if (message) {
            setChatHistory(prevHistory => {
              const updatedHistory = [...prevHistory];
              updatedHistory[updatedHistory.length - 1] = {
                type: 'assistant',
                content: message,
                timestamp: new Date().toISOString()
              };
              return updatedHistory;
            });
          }
        };

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value, { stream: true });
          
          // Extract the JSON part from the chunk
          const jsonString = chunk?.split('data: ')[1];
          if (jsonString) {
            try {
              const parsedData = JSON.parse(jsonString);

              if (parsedData.event === "thread.message.completed") {
                accumulatedResponse = parsedData.message;
                updateChatHistory(accumulatedResponse);
              } else {
                accumulatedResponse += parsedData?.message || '';
                updateChatHistory(accumulatedResponse);
              }

              const threadIdMatch = parsedData?.thread_id;
              if (threadIdMatch) {
                setThreadId(threadIdMatch);
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }
        }
      };

      await processStream();


    } catch (error) {
      console.error('Error in streamed search:', error);
      const errorMessage = { type: 'assistant', content: 'An error occurred while fetching the AI response.', timestamp: new Date().toISOString() };
      setChatHistory(prevHistory => {
        const updatedHistory = [...prevHistory];
        updatedHistory[updatedHistory.length - 1] = errorMessage;
        return updatedHistory;
      });
    } finally {
      setIsLoading(false);
      setRequestInProgress(false);
    }




    
  }, [setChatHistory, setIsLoading, setRequestInProgress, threadId, setThreadId]);

  
  // New function to clear chat history from session storage
  const clearChatHistory = () => {
    sessionStorage.removeItem('chatHistory'); // Clear chat history from session storage
    setChatHistory([]); // Clear chat history state
    setIsSearched(false); // Reset search state
  };

  const messageOptions = [
    { text: "Learn about construction project management", query: "Construction project management" },
    { text: "Help me find the latest construction trends", query: "Latest construction trends" },
    { text: "Come up with innovative construction material ideas", query: "Construction material ideas" },
    { text: "Make a list of essential safety measures in construction", query: "Safety measures in construction" },
  ];

  return (
    <div className='search-container d-flex flex-column'>
      {isSearched && (
        <button 
          onClick={clearChatHistory} 
          aria-label="Clear Chat History"
          className={`clear-chat-button ${isDarkMode ? 'dark-mode' : ''}`}
          title="Start a new chat"
        >
          <LuListRestart size={24} />
        </button>
      )}
      <div className='main-content flex-grow-1'>
        {/* Add the clear chat history button/icon */}
        {isSearched && (
          <button 
            onClick={clearChatHistory} 
            aria-label="Clear Chat History"
            className="clear-chat-button"
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'} // Scale up on hover
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'} // Scale back on mouse leave
            title="Start a new chat" // Tooltip text
          >
            <LuListRestart size={24} /> {/* Display the FiEdit icon */}
          </button>
        )}
        {!isSearched ? (
          <div className='message-container-initial'>
            <h1 className='greeting text-center'>Hello, {accounts.length > 0 ? accounts[0].name.split(' ')[0] : 'User'}</h1>
            <p className='sub-greeting text-center'>How can I help you today?</p>
            <div className='button-container'>
              {messageOptions.map((option, index) => (
                <div 
                  key={index} 
                  className='button-card' 
                  onClick={() => handleSearch(option.query)} // This now sends user query
                >
                  <span>{option.text}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <ChatWindow chatHistory={chatHistory} />
        )}
      </div>
      <div className='input-footer-container w-75 d-flex flex-column align-items-center mx-auto'>
        <form 
          onSubmit={handleSubmit} 
          className="chat-input-form w-100 d-flex justify-content-center" 
          onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSubmit(e)}
        >
          <div style={{ position: 'relative', width: '100%' }}>
            <textarea 
              placeholder='Enter a prompt here' 
              className='prompt-input' 
              style={{ 
                borderRadius: '20px', 
                padding: '10px 20px', 
                paddingRight: '40px',
                border: '1px solid #ccc', 
                width: '100%', 
                height: '60px', 
                maxHeight: '160px', 
                overflowY: 'auto', 
                resize: 'none', 
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
              }} 
              value={inputValue}
              onChange={handleInputChange}
              disabled={isLoading} // Disable when loading
              onInput={(e) => {
                e.target.style.height = 'auto'; // Reset height
                e.target.style.height = `${Math.min(e.target.scrollHeight, 160)}px`; // Set height to scrollHeight or maxHeight
              }}
            />
            {inputValue && (
              <button 
                type="submit"
                style={{
                  position: 'absolute',
                  right: '20px',
                  bottom: '20px',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '30px',
                  color: '#555'
                }}
                className='send-button'
              >
                {isLoading ? "" : <IoIosSend />} {/* Show loading text or icon */}
              </button>
            )}
          </div>
        </form>
        <footer className="text-center pb-2 w-100">
          <p className="mb-0 text-muted footer-text">Gera Assist may display inaccurate info, so double-check its responses. Your privacy & Gera Assist Apps</p>
        </footer>
      </div>
    </div>
  );
};

export default PageSearch;
