import React, { createContext, useContext, useState } from 'react';

// Create a context
const RequestContext = createContext();

// Create a provider component
export const RequestProvider = ({ children }) => {
  const [requestInProgress, setRequestInProgress] = useState(false);

  return (
    <RequestContext.Provider value={{ requestInProgress, setRequestInProgress }}>
      {children}
    </RequestContext.Provider>
  );
};

// Create a custom hook to use the RequestContext
export const useRequest = () => {
  return useContext(RequestContext);
};
