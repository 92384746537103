import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRole } from '../RoleContext';
import '../styles/UnauthorizedPage.css'; // Import the CSS file

const PageUnauthorized = () => {
  const navigate = useNavigate();
  const { fetchUserGroups } = useRole(); // Get the fetchUserGroups function

  const handleRedirect = async () => {
    await fetchUserGroups(); // Fetch roles when navigating to the search page
    navigate('/'); 
  };

  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1>Unauthorized Access</h1>
        <p>You do not have permission to view this page.</p>
        <button className="unauthorized-button" onClick={handleRedirect}>
          Go to Home Page
        </button>
      </div>
    </div>
  );
};

export default PageUnauthorized;
