import React, { useEffect, useState, useCallback } from 'react'; // Added useCallback
import axios from 'axios';
import { FaTrash } from "react-icons/fa6";
import { BsFiletypeDoc } from "react-icons/bs";
import { API_BASE_URL } from '../constants';
import { toast } from 'react-toastify';
import { FaSync } from "react-icons/fa"; // Added import for refresh icon
import { GridLoader } from 'react-spinners'; // Added import for GridLoader


// Debounce function to optimize search input
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};


const DocumentList = ({ fetchDocuments, fetchTrigger }) => {
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('name'); // Default sort by name
  const [filterOption, setFilterOption] = useState('all'); // Default filter
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false); // State for loading documents

  const debouncedSearchQuery = useDebounce(searchQuery, 300); // Debounce search input

  const fetchDocumentsCallback = useCallback(async () => { // Wrapped in useCallback
    setIsLoadingDocuments(true); // Set loading state to true
    try {
      const response = await axios.get(`${API_BASE_URL}/sop-operations/list-sop-files`, {
        headers: {
          'accept': 'application/json',
        },
      });
      const files = response.data.data.map(file => ({
        id: file.id,
        name: file.filename,
        date: new Date(file.created_at * 1000), // Convert timestamp to Date
      }));
      setDocuments(files);
      setFilteredDocuments(files); // Initialize filtered documents
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoadingDocuments(false); // Reset loading state
    }
  }, []); // Now empty dependency array is fine

  useEffect(() => {
    fetchDocumentsCallback();
  }, [fetchDocumentsCallback, fetchTrigger]); // Add fetchTrigger to the dependency array

  const filterDocuments = useCallback(() => { // Wrapped in useCallback
    let filtered = documents.filter(doc =>
      doc.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
    );

    // Apply date filtering
    if (filterOption === 'lastWeek') {
      const lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 7);
      filtered = filtered.filter(doc => doc.date >= lastWeek);
    } else if (filterOption === 'lastMonth') {
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      filtered = filtered.filter(doc => doc.date >= lastMonth);
    }

    // Apply sorting
    if (sortOption === 'name') {
      filtered.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOption === 'date') {
      filtered.sort((a, b) => b.date - a.date); // Sort by date descending
    }

    setFilteredDocuments(filtered);
  }, [debouncedSearchQuery, documents, sortOption, filterOption]); // Removed mockDocuments from dependencies

  useEffect(() => {
    filterDocuments();
  }, [debouncedSearchQuery, documents, sortOption, filterOption, filterDocuments]); // Now filterDocuments is stable

  const handleDelete = async (documentId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/sop-operations/delete-sop-files`, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: `file_ids=${documentId}`, // Send the document ID
      });

      const { failed_deletions } = response.data;

      // Check if the deletion was successful or if the reason indicates a 404 error
      if (failed_deletions.length > 0) {
        const reason = failed_deletions[0].reason;
        if (reason.includes("Error code: 404")) {
          throw new Error(`Failed to delete document: ${reason}`);
        } else if (!reason.includes('FileDeleted')) {
          throw new Error(`Failed to delete document: ${reason}`);
        }
      }
      toast.success('Document deleted successfully');
      const updatedDocuments = filteredDocuments.filter(doc => doc.id !== documentId);
      setDocuments(updatedDocuments);
      setFilteredDocuments(updatedDocuments); // Update filtered documents
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const resetFilters = () => {
    setSortOption('name');
    setFilterOption('all');
    setSearchQuery('');
    fetchDocumentsCallback(); // Fetch documents after resetting filters
  };

  return (
    <div className="document-list">
      <h2>Uploaded Documents</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search documents by name..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
        aria-label="Search documents"
      />

      {/* Sort and Filter Controls */}
      <div className="sort-filter-controls">
        <label htmlFor="sort-select">Sort by:</label>
        <select id="sort-select" value={sortOption} onChange={(e) => setSortOption(e.target.value)} aria-label="Sort options">
          <option value="name">Name</option>
          <option value="date">Date</option>
        </select>

        <label htmlFor="filter-select">Filter:</label>
        <select id="filter-select" value={filterOption} onChange={(e) => setFilterOption(e.target.value)} aria-label="Filter options">
          <option value="all">All Documents</option>
          <option value="lastWeek">Last Week</option>
          <option value="lastMonth">Last Month</option>
        </select>

        <button onClick={resetFilters} className="reset-filters" aria-label="Reset filters">Reset Filters</button>
        <FaSync className="sync-icon refresh-button" aria-label="Refresh documents" onClick={fetchDocumentsCallback} />
      </div>

      {/* Document Table Container */}
      <div className="table-container">
        <table className="document-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingDocuments ? ( // Show GridLoader while loading
              <tr>
                <td colSpan="3" style={{ textAlign: 'center' }}>
                  <GridLoader 
                    color="#7b7b7b" // Set color based on dark mode
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  /> {/* Display GridLoader inside the table */}
                </td>
              </tr>
            ) : (
              filteredDocuments.length > 0 ? (
              filteredDocuments.map((doc) => (
                <tr key={doc.id}>
                  <td><BsFiletypeDoc/> {doc.name}</td>
                  <td>{doc.date.toLocaleDateString()}</td>
                  <td>
                    <button onClick={() => handleDelete(doc.id)} title="Delete" aria-label={`Delete ${doc.name}`}>
                      <FaTrash className="trash-icon"/>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              !isLoadingDocuments && ( // Only show this message if not loading
                <tr>
                  <td colSpan="3" style={{ textAlign: 'center' }}>No results found. Try refining your search.</td>
                </tr>
              )
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DocumentList;
