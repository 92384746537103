import React, { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/gera-logo.png';
import '../styles/LoginPage.css';
import { useRole } from '../RoleContext'; // Import useRole

const PageLogin = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const { fetchUserGroups } = useRole(); // Get the fetchUserGroups function

  useEffect(() => {
    // Check if the user is already logged in
    if (accounts.length > 0) {
      fetchUserGroups(); // Fetch roles when navigating to the login page
      navigate('/search'); // Redirect to the search page if already logged in
    }
  }, [accounts, navigate, fetchUserGroups]);

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  return (
    <div className="container-login d-flex justify-content-center align-items-center">
      <div className='login-container'>
        <div className="d-flex justify-content-center align-items-center logo-login">
          <img src={logo} alt="Logo" className="logo-image" />
        </div>
        <div className="login-form d-flex flex-column align-items-center">
          <h2>SSO Login</h2>
          <button onClick={handleLogin} className="btn btn-primary btn-curved p-2">Sign in with Microsoft</button>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;