import React from 'react';
import '../styles/Modal.css'; // Import the CSS for styling

const ConfirmBox = ({ message, onConfirm, onCancel, theme }) => {
  return (
    <div className={`custom-confirm-modal ${theme}`}>
      <div className="custom-confirm-content">
        <p>{message}</p>
        <div className="custom-confirm-buttons">
          <button className="custom-confirm-button" onClick={onConfirm}>Confirm</button>
          <button className="custom-confirm-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;