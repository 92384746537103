import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from './authConfig';

const RoleContext = createContext();

export const useRole = () => useContext(RoleContext);

export const RoleProvider = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUserGroups = useCallback(async () => {
    if (accounts.length > 0 && inProgress === "none") {
      setLoading(true); // Set loading to true when fetching roles
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        });

        const graphResponse = await fetch('https://graph.microsoft.com/v1.0/me/memberOf', {
          headers: {
            Authorization: `Bearer ${response.accessToken}`
          }
        });

        const groups = await graphResponse.json();
        
        const isAdmin = groups.value.some(group => group.displayName === 'sopm.admin');
        const isCognitiveSearch = groups.value.some(group => group.displayName === 'sopm.nonadmin');

        if (isAdmin) {
          setUserRole('admin');
        } else if (isCognitiveSearch) {
          setUserRole('cognitiveSearch');
        } else {
          setUserRole('unauthorized');
        }
      } catch (error) {
        console.error('Error fetching user groups:', error);
        setUserRole('unauthorized');
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    } else {
      setUserRole(null);
      setLoading(false); // Ensure loading is false if no accounts are found
    }
  }, [accounts, instance, inProgress]);

  useEffect(() => {
    if (inProgress === "none") {
      fetchUserGroups();
    }
  }, [fetchUserGroups, inProgress]);

  return (
    <RoleContext.Provider value={{ userRole, fetchUserGroups, loading }}>
      {children}
    </RoleContext.Provider>
  );
};
