import React, { useState, useEffect } from 'react';
import { Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import logo from '../assets/gera-header.png';
import { FaSignOutAlt, FaMoon, FaSun, FaSearch } from 'react-icons/fa';
import { MdOutlineSettings } from 'react-icons/md';
import { RiCloseLargeLine } from "react-icons/ri";
import { FaBars } from "react-icons/fa6";
import { BiAnalyse } from "react-icons/bi";
import { useRole } from '../RoleContext';
import ContentLoader from 'react-content-loader'; // Import ContentLoader
import { useNavigate } from 'react-router-dom'; // Import useHistory
import { useRequest } from '../contexts/RequestContext'; // Import the custom hook
import ConfirmBox from './ConfirmBox'; // Import ConfirmBox

const Header = ({ isExpanded, setIsExpandedState }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const { instance, accounts } = useMsal();
  const { userRole, loading } = useRole(); // Get loading state
  const { requestInProgress } = useRequest(); // Get the requestInProgress state from context
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [pendingPath, setPendingPath] = useState(''); // New state to store the path
  const isDarkMode = document.body.classList.contains('dark-mode'); // Check for dark mode

  const handleLogout = () => {
    instance.logout();
  };

  const navLinks = [
    { name: 'Cognitive search', path: '/search', icon: <FaSearch />, requiredRole: 'cognitiveSearch' },
    { name: 'Document Analyser', path: '/analyser', icon: <BiAnalyse />, requiredRole: 'admin' },
    { name: 'Admin', path: '/admin', icon: <MdOutlineSettings />, requiredRole: 'admin' }
  ];

  const [isDarkModeState, setIsDarkModeState] = useState(() => localStorage.getItem('darkMode') === 'true');

  const toggleDarkMode = () => {
    setIsDarkModeState(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode);
      document.body.classList.toggle('dark-mode', newMode);
      return newMode;
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkModeState);
  }, [isDarkModeState]);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsExpandedState(false); // Collapse on mobile
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsExpandedState]); // Include setIsExpandedState in the dependency array

  const handleToggle = (e) => {
    e.preventDefault();
    setIsExpandedState(prev => !prev); // Toggle the expanded state
  };

  const backgroundColor = isDarkMode ? "#333" : "#f3f3f3"; // Dark mode background
  const foregroundColor = isDarkMode ? "#ecebeb" : "#000"; // Light mode foreground

  const handleLinkClick = (e, path) => {
    e.preventDefault(); // Prevent default link behavior
    
    if (requestInProgress) {
      setShowConfirm(true);
      setConfirmMessage("A request is in progress. Are you sure you want to navigate away?");
      setPendingPath(path); // Store the path
    } else {
      navigate(path);
    }
  };

  const handleNavigationConfirm = () => {
    setShowConfirm(false);
    navigate(pendingPath);
  };

  return (
    <>
    <Nav className={`flex-column side-nav ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="nav-content">
        <div className="nav-brand d-flex align-items-center justify-content-between">
          <Button 
            variant="link" 
            className="nav-toggle p-0" 
            onClick={handleToggle}
          >
            {isExpanded ? <RiCloseLargeLine /> : <FaBars />}
          </Button>
          {isExpanded && (
            <Link to="/search" className="brand-link px-2 pb-2">
              <img src={logo} alt="Gera Logo" className="nav-logo-image" /> Gera Assist{/* Use the new logo */}
            </Link>
          )}
        </div>
        
        {/* Show loading content if roles are loading */}
        {loading ? (
            <ContentLoader 
              speed={2}
              width={400}
              height={160}
              viewBox="0 0 400 160"
              backgroundColor={backgroundColor}
              foregroundColor={foregroundColor}
            >
              <rect x="0" y="20" rx="5" ry="5" width="200" height="20" /> 
              <rect x="0" y="60" rx="5" ry="5" width="200" height="20" /> 
              <rect x="0" y="100" rx="5" ry="5" width="200" height="20" /> 
              <rect x="0" y="140" rx="5" ry="5" width="200" height="20" /> 
            </ContentLoader>
        ) : (
          <div>
          <div className="link-container" >
            {navLinks.filter(link => userRole === 'admin' || link.requiredRole === userRole).map((link, index) => (
              <Nav.Item key={index} className={`${isExpanded ? 'py-1' : 'py-1'}`}>
                <Nav.Link 
                  as={Link} 
                  to={link.path} 
                  className="nav-link font-weight-bold" 
                  onClick={(e) => handleLinkClick(e, link.path)}
                >
                  <span className="nav-icon">{link.icon}</span>
                  <span className="nav-text">{link.name}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </div>
          <Button 
          variant="link"
          className={`nav-link font-weight-bold ${isExpanded ? 'py-1' : 'pt-3 pb-3 py-1'}`}
          onClick={toggleDarkMode}
        >{isDarkMode ? <FaSun /> : <FaMoon />}<span className="nav-text p-2">{isDarkMode ? 'Light Mode' : 'Dark Mode'}</span></Button>

          </div>
        )}

       
       {/* Logout button */}
       {loading ? (
        <ContentLoader 
          speed={2}
          width={400}
          height={160}
          viewBox="0 0 400 160"
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
        > 
        <rect x="0" y="20" rx="5" ry="5" width="200" height="20" /> 
        </ContentLoader>
        ) : (
         
            accounts.length > 0 && (
              <Button 
                variant="outline-light" 
                className={`btn-logout ${isExpanded ? 'btn-expanded' : 'btn-collapsed'}`} 
                onClick={handleLogout}
              >
                {isExpanded ? 'Logout' : <FaSignOutAlt />}
              </Button>
            )
          
        )}
      </div>

    </Nav>
      {showConfirm && (
        <ConfirmBox 
          message={confirmMessage}
          onConfirm={handleNavigationConfirm}
          onCancel={() => setShowConfirm(false)} 
          theme={isDarkMode ? "dark" : "light"}
        />
      )}
    </>
    
  );

};

export default Header;
