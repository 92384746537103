import { PublicClientApplication } from "@azure/msal-browser";
import { AZURE_CLIENT_ID, AZURE_AUTHORITY, AZURE_REDIRECT_URI, AZURE_TENANT_ID } from "./constants";
const msalConfig = {
    // auth: {
    //     clientId: "3ba92663-5a5a-4fd6-a461-56be3c0b3fe0", // This seems to be correct
    //     authority: "https://login.microsoftonline.com/de21737f-d6b1-425f-ac21-67db0eec5c98", // Corrected tenant ID
    //     redirectUri: "http://localhost:3000/auth", // Make sure this matches your app's configuration
    //   },
      auth: {
        clientId: AZURE_CLIENT_ID, // This seems to be correct
        authority: AZURE_AUTHORITY+AZURE_TENANT_ID, // Corrected tenant ID
        redirectUri: AZURE_REDIRECT_URI, // Make sure this matches your app's configuration
        scopes: ["https://graph.microsoft.com/.default"]
      },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read", "GroupMember.Read.All", "Sites.Read.All"]
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Initialize the MSAL instance
export const initializeMsal = async () => {
    await msalInstance.initialize(); // Ensure this is called
};